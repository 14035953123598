
export const EnumToArray = (enums: any[]) => {
  let empty = []
  let newArray = []

  enums.forEach(item => {
    empty.push(Object.values(item));
  });

  empty.forEach((_, index) => {
    newArray.push(...empty[index]);
  });

  return newArray
}